import {querySelector} from '../utilities';

const SHOULD_BLOCK_SCREEN_SHOT = true;

interface NavigatorUABrandVersion {
  readonly brand: string;
  readonly version: string;
}

interface UADataValues {
  readonly brands?: NavigatorUABrandVersion[];
  readonly mobile?: boolean;
  readonly platform?: string;
  readonly model?: string;
  readonly platformVersion?: string;
}

interface NavigatorUAData {
  getHighEntropyValues(hints: string[]): Promise<UADataValues>;
}

interface Navigator {
  readonly userAgentData?: NavigatorUAData;
}


const checkOldUserAgent = () => {
  if (navigator?.userAgent?.indexOf('Android') ?? false) {
    const versionText = navigator.userAgent.replace(/.*Android\s*([\d.]+);.*/, '$1');
    const versionValue = Number.parseFloat(versionText);
    if (!Number.isNaN(versionValue) && versionValue >= 13) {
      return false;
    }
  }
  return true;
};

const checkClipboardIsAvailable = (): Promise<boolean> => {
  return new Promise<boolean>(async resolve => {

    const userAgentData = (navigator as Navigator).userAgentData;
    if (!userAgentData) {
      return resolve(checkOldUserAgent());
    }

    const dataValues = await userAgentData.getHighEntropyValues(['platformVersion']);
    if (dataValues.platform.toUpperCase() === 'ANDROID') {
      if (parseFloat(dataValues.platformVersion) >= 13) {
        return resolve(false);
      }
    }
    return resolve(true);
  });
};


if (SHOULD_BLOCK_SCREEN_SHOT) {

  let focused = true;

  const shouldBlockCopy = () => !!querySelector('.block-copy');

  const clearClipboard = (shouldUseClipboard: boolean) => {
    if (!shouldUseClipboard) {
      return;
    }
    if ((window as any).clipboardData) {
      (window as any).clipboardData.setData('text', ' ');
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(' ').catch(() => {
      });
    }
  };

  const isKeyCode = (e: KeyboardEvent, code: any) => {
    if ((e as any).keyCode == code) {
      return true;
    }
    return (e as any).which == code;
  };

  document.addEventListener('DOMContentLoaded', async () => {
    if (!shouldBlockCopy()) {
      return;
    }

    const shouldUseClipboard = await checkClipboardIsAvailable();

    document.addEventListener('contextmenu', e => {

      const element = e.target as HTMLElement;
      if (!element || !element.matches) {
        return;
      }
      if (!element.matches('.photo-wrapper > img')) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();
      return false;
    });

    window.addEventListener('blur', () => {
      setTimeout(() => {
        document.body.style.opacity = '0';
        document.body.classList.add('blocking-copy');
        focused = false;
        if (shouldUseClipboard) {
          if ((window as any).clipboardData) {
            (window as any).clipboardData.setData('text', ' ');
          }
          if (navigator.clipboard) {
            navigator.clipboard.writeText(' ').then().catch(() => {
            });
          }
        }
      }, 200);
    });

    window.addEventListener('focus', () => {
      setTimeout(() => {
        document.body.style.opacity = '1';
        document.body.classList.remove('blocking-copy');
        focused = true;
      }, 200);
    });

    document.addEventListener('keydown', e => {
      if (isKeyCode(e, 91) || isKeyCode(e, 93) || isKeyCode(e, 29)) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    document.addEventListener('keyup', e => {
      if (isKeyCode(e, 29) || isKeyCode(e, 44)) {
        clearClipboard(shouldUseClipboard);
      }
    });

    setInterval(
        () => {
          if (!focused) {
            return;
          }

          if (shouldBlockCopy()) {
            clearClipboard(shouldUseClipboard);
          }
        }, 100);
  });
}

